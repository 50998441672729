import React from "react"

import Layout from "../../../../components/layout"
import SEO from "../../../../components/seo"

import BookingForm from "../../../../components/dynamic/BookingForm"

function AwesomeCombo(props) {
  return (
    <>
      <SEO title="Bay of Islands Dolphin Cruise + Cape Reinga or Hokianga - awesomeNZ" />
      <Layout
        layoutType="typography TourPage"
        slideshow={
          <ul class="slideshow-slides">
            <li class="slideshow-slide first">
              <img
                src={require("../../../../images/sliders/bay-of-islands-dolphins.jpg")}
                alt=""
                class="first image"
              />
            </li>
            <li class="slideshow-slide">
              <img
                src={require("../../../../images/sliders/cape-reinga-sand-boarding.jpg")}
                alt=""
                class="image"
              />
            </li>
            <li class="slideshow-slide">
              <img
                src={require("../../../../images/sliders/cape-reinga-bus.jpg")}
                alt=""
                class="image"
              />
            </li>
            <li class="slideshow-slide">
              <img
                src={require("../../../../images/sliders/bay-of-islands-awesome-cruise.jpg")}
                alt=""
                class="image"
              />
            </li>
            <li class="slideshow-slide">
              <img
                src={require("../../../../images/tours/bay-of-islands-awesomenz-hole-in-rock.jpg")}
                alt=""
                class="last image"
              />
            </li>
          </ul>
        }
        hasSliderHandles={true}
        hasSliderBlurLarge={true}
        content={
          <>
            {/* bookingsource: AWWEB, cat: CRK, tour: CRK */}
            <BookingForm tourCode="CRK" />

            <div id="row" class="fourteen clearfix">
              <div class="title-div clearfix">
                <h1>awesome Combo - Double Dip</h1>
                <h3>
                  See the best of Northland on an action-packed two-day tour
                  combo
                </h3>
                <div class="highlights">
                  <ul>
                    <li>Bay of Islands dolphin watching cruise</li>
                    <li>Cape Reinga day tour OR Hokianga day tour</li>
                    <li>Expert driver commentary</li>
                    <li>Free hotel pick ups and drop-offs</li>
                  </ul>
                </div>
              </div>
              <div id="Overview" class="clearfix">
                <div id="Content" class="clearfix">
                  <div id="ContentTab">
                    {/* Content from awesomeNZ tour page goes here */}
                    <p>
                      Explore the Bay of Islands by land and sea and save up to
                      18% with this great value <em>Double Dip</em> awesomeNZ
                      combo. Experience a{" "}
                      <strong>dolphin watching cruise</strong> from Paihia
                      (afternoon cruise only) and return day tour from Paihia to
                      either <strong>Cape Reinga</strong> OR the{" "}
                      <strong>Hokianga region</strong>.
                    </p>
                    <p>
                      Take a half-day <strong>Hole in the Rock</strong> cruise
                      and explore the Bay in search of dolphins while visiting
                      its highlights. You could be watching dolphins as they
                      leap and play in sheltered waters - or keep an eye out for
                      other marine mammals like whales and seals. Cruise in and
                      out of beautiful unspoilt islands, see the remote{" "}
                      <strong>Cape Brett Lighthouse</strong> and the impressive
                      Hole in the Rock at Motukōkako Island.
                    </p>
                    <p>
                      If you choose the Cape Reinga day tour, be ready for a
                      journey like no other with a full-day coach tour into the
                      Far North of New Zealand. Along the way you'll visit
                      ancient kauri forests, drive along the sand of{" "}
                      <strong>Ninety Mile Beach</strong> and even have a go at
                      sandboarding – surfing down the massive sand dunes at{" "}
                      <strong>Te Paki Stream</strong>. A visit to the very tip
                      of Aotearoa (New Zealand) will open your eyes to Māori
                      culture as you learn of the spiritual significance of this
                      place where two oceans collide.
                    </p>
                    <p>
                      Or opt for an adventure into the Hokianga - a unique part
                      of New Zealand, first discovered by Kupe the voyager and
                      later, home to some of the very first European settlers.
                      Hear the stories of this region steeped in history and
                      experience the almost mystical atmosphere in this
                      untouched part of the world. Visit the{" "}
                      <strong>Waipoua Forest</strong> and meet a national icon,
                      the mighty kauri tree <strong>Tāne Mahuta</strong>. Spend
                      time in the beachside town of <strong>Opononi</strong>,
                      and discover the magic of the{" "}
                      <strong>Kawiti Caves</strong>, home to stunning rock
                      formations and galaxies of twinkling glowworms.
                    </p>
                    <p>Book now and let the adventure begin!</p>
                    <p>Price will increase to $228 from 14 October 2019.</p>

                    <div class="clear"></div>

                    <div id="TravellerReviews0">
                      <div id="TravellerReviews">
                        <div id="TA_selfserveprop962" class="TA_selfserveprop">
                          <ul id="CvqcAGWx7Uu" class="TA_links R7bciFVoJQI">
                            <li id="0mQ7w1HIP2" class="beBGy9iqY">
                              TripAdvisor Reviews of{" "}
                              <a
                                href="https://www.tripadvisor.com/Attraction_Review-g255110-d1546340-Reviews-AwesomeNZ_com_Cape_Reinga_90_Mile_Beach_Tour-Paihia_Bay_of_Islands_Northland_Regi.html"
                                rel="noopener noreferrer"
                                target="_blank"
                              >
                                awesomeNZ.com - Cape Reinga / 90 Mile Beach Tour
                              </a>{" "}
                              in Paihia
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* ... */}
                    <div class="clear"></div>
                  </div>
                </div>
              </div>
            </div>
          </>
        }
      />
    </>
  )
}

export default AwesomeCombo
